.circle {
  width: 100px;
  height: 100px;
  position: relative;
}
.circle .child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.circle .child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 1px;
  animation: circleBounceDelay 1.2s infinite ease-in-out both;
}
.circle .circle2 {
  transform: rotate(30deg);
}
.circle .circle3 {
  transform: rotate(60deg);
}
.circle .circle4 {
  transform: rotate(90deg);
}
.circle .circle5 {
  transform: rotate(120deg);
}
.circle .circle6 {
  transform: rotate(150deg);
}
.circle .circle7 {
  transform: rotate(180deg);
}
.circle .circle8 {
  transform: rotate(210deg);
}
.circle .circle9 {
  transform: rotate(240deg);
}
.circle .circle10 {
  transform: rotate(270deg);
}
.circle .circle11 {
  transform: rotate(300deg);
}
.circle .circle12 {
  transform: rotate(330deg);
}
.circle .circle2:before {
  animation-delay: -1.1s;
}
.circle .circle3:before {
  animation-delay: -1s;
}
.circle .circle4:before {
  animation-delay: -0.9s;
}
.circle .circle5:before {
  animation-delay: -0.8s;
}
.circle .circle6:before {
  animation-delay: -0.7s;
}
.circle .circle7:before {
  animation-delay: -0.6s;
}
.circle .circle8:before {
  animation-delay: -0.5s;
}
.circle .circle9:before {
  animation-delay: -0.4s;
}
.circle .circle10:before {
  animation-delay: -0.3s;
}
.circle .circle11:before {
  animation-delay: -0.2s;
}
.circle .circle12:before {
  animation-delay: -0.1s;
}

@-webkit-keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
